.g-a-spin-1:hover {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  -o-transition: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.g-a-spin-1-btn:hover > span > i {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  -o-transition: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.g-a-spin-2:hover {
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -o-transition: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.g-a-spin-2-btn:hover > span > i {
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -o-transition: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.g-a-spin-3:hover {
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  -o-transition: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.g-a-spin-3-btn:hover > span > i {
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  -o-transition: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.g-a-spin-4:hover {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  -o-transition: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.g-a-spin-4-btn:hover > span > i {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  -o-transition: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.g-a-spin-5:hover {
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  -o-transition: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}

.g-a-spin-5-btn:hover > span > i {
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  -o-transition: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}

.g-a-spin-6:hover {
  -webkit-animation: spin 6s linear infinite;
  -moz-animation: spin 6s linear infinite;
  -o-transition: spin 6s linear infinite;
  animation: spin 6s linear infinite;
}

.g-a-spin-6-btn:hover > span > i {
  -webkit-animation: spin 6s linear infinite;
  -moz-animation: spin 6s linear infinite;
  -o-transition: spin 6s linear infinite;
  animation: spin 6s linear infinite;
}

.g-a-spin-7:hover {
  -webkit-animation: spin 7s linear infinite;
  -moz-animation: spin 7s linear infinite;
  -o-transition: spin 7s linear infinite;
  animation: spin 7s linear infinite;
}

.g-a-spin-7-btn:hover > span > i {
  -webkit-animation: spin 7s linear infinite;
  -moz-animation: spin 7s linear infinite;
  -o-transition: spin 7s linear infinite;
  animation: spin 7s linear infinite;
}

.g-a-spin-8:hover {
  -webkit-animation: spin 8s linear infinite;
  -moz-animation: spin 8s linear infinite;
  -o-transition: spin 8s linear infinite;
  animation: spin 8s linear infinite;
}

.g-a-spin-8-btn:hover > span > i {
  -webkit-animation: spin 8s linear infinite;
  -moz-animation: spin 8s linear infinite;
  -o-transition: spin 8s linear infinite;
  animation: spin 8s linear infinite;
}

.g-a-spin-9:hover {
  -webkit-animation: spin 9s linear infinite;
  -moz-animation: spin 9s linear infinite;
  -o-transition: spin 9s linear infinite;
  animation: spin 9s linear infinite;
}

.g-a-spin-9-btn:hover > span > i {
  -webkit-animation: spin 9s linear infinite;
  -moz-animation: spin 9s linear infinite;
  -o-transition: spin 9s linear infinite;
  animation: spin 9s linear infinite;
}

.g-a-spin-10:hover {
  -webkit-animation: spin 10s linear infinite;
  -moz-animation: spin 10s linear infinite;
  -o-transition: spin 10s linear infinite;
  animation: spin 10s linear infinite;
}

.g-a-spin-10-btn:hover > span > i {
  -webkit-animation: spin 10s linear infinite;
  -moz-animation: spin 10s linear infinite;
  -o-transition: spin 10s linear infinite;
  animation: spin 10s linear infinite;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(360deg);
  }
  25% {
    -moz-transform: rotate(360deg);
  }
  50% {
    -moz-transform: rotate(360deg);
  }
  75% {
    -moz-transform: rotate(360deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.g-auto-hide > div:last-child {
  display: none;
}

.g-auto-hide:hover > div:first-child {
  display: none;
}

.g-auto-hide:hover > div:last-child {
  display: inherit;
}